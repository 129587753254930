import React, { useState } from 'react'
import { QuotationClientForm } from '../quotationMain/clientForm'
import TextSB from '../common/CustomTypography.component';
import Button from '../common/button/Button.component';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { QuotationExpertiseForm } from '../quotationMain/QuotationExpertiseForm';
import { SEND_SALE_CAR_FORM_TO_ASOFIX } from '../../mutations/sendSaleCarFormToAsofix';
import { useMutation, useQuery } from '@apollo/react-hooks';
import ResponsiveStatusModal from '../common/ResponsiveStatusModal.component';
import { SUCCESS_MODAL_TEXT } from '../../containers/catalog-detail/constant';
import { GET_QUOTE_FORM } from '../../queries/getQuoteForm';

const TITLE = 'Dejanos tus datos'
const SUBTITLE = 'Te contactaremos para coordinar un peritaje y continuar con la compra de tu usado.'

const useStyle = makeStyles((theme) => ({
  container: {
    marginTop: '40px',
    width: '610px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0 4px',
      marginTop: '8px',
    },
  },
  buttons: {
    marginTop: '48px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    gap: '16px',
    marginBottom: '88px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '24px',
      marginRight: '16px',
    },
  },
  title: {
    padding: '1rem 1rem 0 1rem',
  },
}))

export const ClientData = ({ handleBackStep, handleNextStep, car, quote, setFormChanged }) => {
  const classes = useStyle()
  const theme = useTheme()
  const [checkAutoperitaje, setCheckAutoperitaje] = React.useState(true)
  const [ formData, setFormData ] = React.useState({
    client: {},
    clientError: {},
    isValidClient: false,
  })
  const [success, setSuccess] = useState(false)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [openModalResponse, setOpenModalResponse] = useState(false)

  const { data } = useQuery(GET_QUOTE_FORM)

  const [ sendSaleCarForm, { loading } ] =
  useMutation(SEND_SALE_CAR_FORM_TO_ASOFIX, {
    onCompleted: () => {
      setSuccess(true)
      if (checkAutoperitaje){
        window.open(data.getQuoteForm, '_blank')
      }
      handleNextStep()
      window.scrollTo(0, 0)
    },
    onError: (error) => {
      console.log(error)
      setSuccess(false)
    },
  })

  const onClickSend = async () => {
    const { client, isValidClient } = formData
    if (!isValidClient) return
    const contactForm = {
      car: {
        brand: { id: car.brand.value[0], name: car.brand.label },
        model: { id: car.model.value[0], name: car.model.label },
        version: { id: car.version.value, name: car.version.label },
        year: parseInt(car.year.value),
        mileage: parseInt(car.km),
        isKmsCalculated: car.isKmsCalculated,
        condition: 0,
        tipo_cotizador: 'Autos',
      },
      client: {
        name: client.firstName,
        lastName: client.lastName,
        email: client.email,
        phone: client.phone,
        appraisalLocation: checkAutoperitaje ? 'Autoperitaje' : 'Peritaje presencial',
      },
      quote
    }
    sendSaleCarForm({ variables: { contactForm } })
    setOpenModalResponse(true)
    setFormChanged(false)
  }


  return (
    <div className={classes.container}>
      <div className={classes.title} >
        <TextSB variant='h3'>{TITLE}</TextSB>
        <TextSB variant='body1'>{SUBTITLE}</TextSB>
      </div>
      <QuotationClientForm
        formData={formData}
        setFormData={setFormData}
        noTitle
        showNameOneLine
      />
      <QuotationExpertiseForm checkAutoperitaje={checkAutoperitaje} setCheckAutoperitaje={setCheckAutoperitaje} />
      <div className={classes.buttons} >
        <Button id='cotiza-btn-p3-volver' type='secondary' onClick={() => handleBackStep()} >
          <span id='id=cotiza-btn-p3-volver-txt'>Volver</span>
        </Button>
        <Button id='cotiza-btn-p3-enviar' onClick={onClickSend} >
          <span id='cotiza-btn-p3-enviar-txt'>Enviar</span>
        </Button>
      </div>
      <ResponsiveStatusModal
        success={success}
        loading={loading}
        isMobile={isMobile}
        open={openModalResponse}
        loadingMessage={SUCCESS_MODAL_TEXT.loadingMessage}
        loadingLegend={SUCCESS_MODAL_TEXT.loadingLegend}
        errorMessage={SUCCESS_MODAL_TEXT.errorMessage}
        errorLegend={SUCCESS_MODAL_TEXT.errorLegend}
        errorTextBtn={SUCCESS_MODAL_TEXT.errorTextBtn}
        onErrorBtnClick={() => {
          setOpenModalResponse(false)
        }}
        onClose={() => setOpenModalResponse(false)}
      />
    </div>
  )
}
