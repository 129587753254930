import React, { useEffect } from 'react'
import ThisIsOurProposalForYou from '../quotationMain/ThisIsOurProposalForYou'
import { makeStyles } from '@material-ui/core';
import Button from '../common/button/Button.component';
import { QuotationLegalBox } from '../quotationMain/QuotationLegalBox';
import { calculateKms } from '../common/quotationHelper';
import { useQuery } from '@apollo/react-hooks'
import { GET_QUOTATION } from '../../queries'
import Loading from '../common/Loading.component';

const REFERENCE_PRICE = 'reference'
const ESTIMATED_PRICE = 'estimated'

const useStyle = makeStyles((theme) => ({
  container: {
    marginTop: '88px',
    width: '616px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    [theme.breakpoints.down('sm')]: {
      marginTop: '24px',
      width: '100%',
    },
  },
  buttons: {
    marginTop: '48px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    gap: '16px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '24px',
      marginRight: '16px',
    },
  },
  spinner: {
    marginTop: '88px',
    marginBottom: '88px',
  },
}))

export const CarQuotation = ({ car, handleBackStep, handleNextStep, setQuote, setCar, quote }) => {
  const classes = useStyle()

  const { data: dataGetQuotation, loading } = useQuery(GET_QUOTATION, {
    skip: !car.brand.value || !car.model.value || !car.version.value || !car.year.value,
    variables: {
      info: {
        interior: 5,
        kms: parseInt(calculateKms(car.km, car.year.value, car.version.tipoCotizador)),
        vehicle: {
          brand: car.brand.value,
          model: car.model.value,
          version: car.version.value,
          year: car.year.value,
        },
      },
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!dataGetQuotation?.carQuote?.quote?.prices) return;
    const calcPrice = (price) => dataGetQuotation.carQuote?.quote?.prices?.find(q => q?.type === price)?.amount;
    setCar((prevData) => ({
      ...prevData,
      isKmCalculated: Boolean(car?.km)
    }))
    setQuote((prevData) => ({
      ...prevData,
      prices: {
        reference: calcPrice(REFERENCE_PRICE),
        estimated: calcPrice(ESTIMATED_PRICE),
      },
      date: dataGetQuotation.carQuote.date,
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGetQuotation])

  const estimatedPrice = quote?.prices?.estimated

  return loading ? <Loading className={classes.spinner} /> : <div className={classes.container}>
    <ThisIsOurProposalForYou formData={{ car }} price={estimatedPrice} />

    <div className={classes.buttons} >
      <Button id='cotiza-btn-p2-volver' type='secondary' onClick={() => handleBackStep()} >
        <span id='cotiza-btn-p2-volver-txt'>Volver</span>
      </Button>
      <Button id='cotiza-btn-p2--continuar' onClick={() => handleNextStep()} >
        <span id='cotiza-btn-p2-continuar-txt'>Me interesa</span>
      </Button>
    </div>
    <QuotationLegalBox mobilePadding='2rem 1rem 0rem 1rem' />
  </div>
}
