import { makeStyles } from '@material-ui/core'
import React from 'react'
import { SellYourCardBanner } from '../../components/sellYourCar/banner'
import { SellYourCarCarInfo } from '../../components/sellYourCar/carForm'
import { CarQuotation } from '../../components/sellYourCar/carQuotation'
import { ClientData } from '../../components/sellYourCar/clientData'
import SaleCarSuccess from '../../components/sell-your-car/SaleCarSuccess.component'
import { showModalExitPrompt } from '../../context/modalExitPrompt/utils'
import ModalExitPrompt from '../../components/common/ModalExitPrompt.component'
import { useAppContext } from '../../context/AppContext'
import { SUBTITLE_MODAL_PROMPT, TITLE_MODAL_PROMPT } from '../../components/common/constant'
import setFormHasData from '../../context/modalExitPrompt/actions/setFormHasData'

const useStyle = makeStyles(() => ({
  container: {
    width: '100%',
    padding: 0,
  },
  successContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 1rem',
  },
  bannerContainer: {
    marginTop: '8px',
  },
}))

const SellYourCarContainer = ({ location }) => {
  const classes = useStyle()
  const [ car, setCar ] = React.useState({})
  const [ quote, setQuote ] = React.useState({})
  const [ step, setStep ] = React.useState(1)

  const {
    modalExitPrompt,
    modalExitPromptDispatch,
  } = useAppContext()

  const handleEmptyForm = ( canExit) => {
    setFormHasData(modalExitPromptDispatch, canExit)
  }

  const showModalExitFunction = (prevRoute, nextRoute) =>
    showModalExitPrompt(prevRoute, nextRoute, modalExitPrompt)

  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1)
  }

  const handleBackStep = () => {
    setStep((prevStep) => prevStep - 1)
  }

  return (
    <div className={classes.container} >
      {step !== 4 && <div className={classes.bannerContainer}><SellYourCardBanner /></div>}

      {step === 1 && <SellYourCarCarInfo
        car={car}
        setFormChanged={handleEmptyForm}
        onSetCar={ dataCar => {
          setCar(dataCar)
          handleNextStep()
        }}
      />}

      {step === 2 && <CarQuotation car={car} quote={quote} handleNextStep={handleNextStep} handleBackStep={handleBackStep} setQuote={setQuote} setCar={setCar}/>}

      {step === 3 && <ClientData
        handleNextStep={handleNextStep}
        handleBackStep={handleBackStep}
        car={car}
        quote={quote}
        setFormChanged={handleEmptyForm}
      />}

      {step === 4 && <div className={classes.successContainer} >
        <SaleCarSuccess />
      </div>}

      <ModalExitPrompt
        title={TITLE_MODAL_PROMPT}
        subtitle={SUBTITLE_MODAL_PROMPT}
        primaryActionText="Salir"
        secondaryActionText="Volver"
        originRoute={location.pathname}
        showModal={showModalExitFunction}
      />

    </div>
  )
}

export default SellYourCarContainer
