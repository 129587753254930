import React from 'react';
import Layout from '../components/layouts/layout.component';
import SellYourCarContainer from '../containers/sell-your-car/SellYourCar.container';

const PAGE_TITLE = 'Cotizá';
// eslint-disable-next-line max-len
const META_DESCRIPTION = 'Cotizá';

const CotizaPage = ({ location, pageContext }) => {
  return (
    <Layout
      title={PAGE_TITLE}
      description={META_DESCRIPTION}
      location={location}
      pageContext={pageContext}
      showSubHeader
    >
      <SellYourCarContainer location={location} />
    </Layout>
  );
};

export default CotizaPage;
