import { graphql, useStaticQuery } from 'gatsby';
import React from 'react'
import Image from 'gatsby-image';
import TextSB from '../common/CustomTypography.component';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme, useMediaQuery } from '@material-ui/core';

const TITLE = 'Vendé tu auto en Carmuv'
const SUBTITLE = 'Cotizás y recibís tu oferta al instante.'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
  },
  text: {
    position: 'absolute',
    top: '49%',
    left: '55%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    [theme.breakpoints.down('sm')]: {
      top: '52%',
      left: '1rem',
      textAlign: 'left',
    },
  },
}));

export const SellYourCardBanner = () => {
  const data = useStaticQuery(query);
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.container} >
      <Image fluid={isMobile
        ? data.bannerImgMobile.childImageSharp.fluid
        : data.bannerImgDesktop.childImageSharp.fluid
      } />
      <div className={classes.text} >
        <TextSB variant='h2' customColor='blanco' >{TITLE}</TextSB>
        <TextSB variant='body1' customColor='blanco' >{SUBTITLE}</TextSB>
      </div>
    </div>
  )
}

const query = graphql`
query {
  bannerImgDesktop: file(
    relativePath: { eq: "banner-desktop-onboarding.png" }
  ) {
    childImageSharp {
      fluid(maxWidth: 1366, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  bannerImgMobile: file(
    relativePath: { eq: "banner-mobile-onboarding.png" }
  ) {
    childImageSharp {
      fluid(maxWidth: 360, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}`;
