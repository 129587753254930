import React, { useEffect } from 'react'
import { SelectSB } from '../common/forStorybook/SelectSB'
import { makeStyles } from '@material-ui/core/styles';
import TextSB from '../common/CustomTypography.component';
import Button from '../common/button/Button.component';
import TextFieldSB from '../common/forStorybook/TextField.component';
import { useQuery } from '@apollo/react-hooks';
import { GET_MODEL_VERSIONS } from '../../queries/getModelVersions';
import { GET_MODEL_YEARS } from '../../queries/getModelYears';
import { GET_MODELS, GET_BRANDS } from '../../queries';
import { calculateKms } from '../common/quotationHelper';

const NO_OPTIONS = 'No hay opciones disponibles'
const REQUIRED_ERROR_TEXT = 'Este campo es requerido'

const useStyle = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '32px',
    width: '100%',
    maxWidth: '616px',
    padding: 0,
    margin: '88px auto 88px',
    '& .MuiFormControl-root':{
      marginTop: 0,
    },
    '& .MuiFormLabel-root':{
      marginLeft: '6px',
    },
    '& .MuiFormLabel-filled':{
      fontWeight: '500',
    },
    '& .MuiAutocomplete-input:first-child':{
      marginLeft: '6px',
    },
    [theme.breakpoints.down('sm')]: {
      gap: '16px',
      width: '100%',
      margin: '48px auto 56px',
      paddingLeft: '16px',
      paddingRight: '16px',
    },
  },
  title: {
    marginBottom: '10px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '24px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '16px',
    },
  },
  button: {
    textAlign: 'right',
  },
  selectField: {
    width: '100%',
  },
}))

const REQUIRED_FIELDS = ['brand', 'model', 'year', 'version']

export const SellYourCarCarInfo = ({ onSetCar, car, setFormChanged = () => {} }) => {
  const classes = useStyle()
  const [ formData, setFormData ] = React.useState({})
  const [ errorList, setErrorList ] = React.useState([])
  const [ brands, setBrands ] = React.useState([])
  const [ models, setModels ] = React.useState([])
  const [ years, setYears ] = React.useState([])
  const [ versions, setVersions ] = React.useState([])

  const updateFormData = ( keyValue ) => setFormData( (prev) => {
    setFormChanged(true)
    return { ...prev, ...keyValue }
  })

  useEffect(() => {
    if (car) {
      if (car?.isKmsCalculated) {
        setFormData({ ...car, km: '' })
      } else {
        setFormData(car)
      }
    }
  }, [car])

  const { loading: isLoadingBrand } = useQuery(GET_BRANDS, {
    variables: { published: null },
    onCompleted: (data) => {
      setBrands(data.brands.map( (brand) => ({ label: brand.name, value: brand.id[0] }) ))
    }
  });

  const { loading: isLoadingModels } = useQuery(GET_MODELS, {
    skip: !formData?.brand?.value,
    variables: { brandId: formData?.brand?.value , published: null, requiredPrices: true },
    onCompleted: (data) => {
      if (!data?.models) {
        setModels([])
        return
      }
      setModels(data.models.map( (model) => ({ label: model.name, value: model.id[0] }) ))
    }
  });

  const { loading: isLoadingYears } = useQuery(GET_MODEL_YEARS, {
    skip: !formData?.brand?.value || !formData?.model?.value,
    variables: {
      brandId: formData?.brand?.value,
      modelId: formData?.model?.value,
    },
    onCompleted: (data) => {
      if (!data?.years) {
        setYears([])
        return
      }
      setYears(data.years.map( (year) => ({ label: year.label, value: year.year }) ))
    }
  });

  const { loading: isLoadingVersions } = useQuery(GET_MODEL_VERSIONS, {
    skip: !formData?.year?.value || !formData?.brand?.value || !formData?.model?.value,
    variables: {
      brandId: formData?.brand?.value,
      modelId: formData?.model?.value,
      year: formData?.year?.value,
    },
    onCompleted: (data) => {
      if (!data?.versions) {
        setVersions([])
        return
      }
      setVersions(data.versions.map( (version) => ({
        label: version.name,
        value: version.id,
        tipoCotizador: version.tipo_cotizador,
      }) ))
    }
  });

  const handleSendButton = () => {
    const newErrorList = REQUIRED_FIELDS.reduce( (acc, field) => {
      if ( !formData[field] ) {
        acc.push(field)
      }
      return acc
    }, [])
    setErrorList(newErrorList)
    if (newErrorList.length === 0) {
      const isKmsCalculated = !formData.km
      const km = calculateKms(formData.km, formData.year.value, formData.version.tipoCotizador)
      onSetCar({ ...formData, km, isKmsCalculated })
    }
  }

  const clearError = (fields) => {
    setErrorList( (prev) => prev.filter( (field) => !fields.includes(field) ) )
  }

  return <div className={classes.container} >

    <div className={classes.title}>
      <TextSB variant='h3' >Contanos sobre tu auto</TextSB>
    </div>
    <div id='select-brand'>
    <SelectSB
      label='Marca'
      loading={isLoadingBrand}
      error={errorList.includes('brand')}
      helperText={errorList.includes('brand') ? REQUIRED_ERROR_TEXT : null}
      onChange={ (val) => {
        updateFormData(
          {
            brand: val,
            model: null,
            year: null,
            version: null,
          })
        clearError(['brand', 'model', 'year', 'version'])
      }}
      value={formData.brand}
      noOptionsText={NO_OPTIONS}
      options={brands}
      disableClearable
    />
    </div>

    <div className={classes.row} >
      <div id='select-model' className={classes.selectField}>
      <SelectSB
        label='Modelo'
        loading={isLoadingModels}
        helperText={(formData.brand && errorList.includes('model')) ? REQUIRED_ERROR_TEXT : null}
        error={errorList.includes('model') && formData.brand}
        onChange={ (val) => {
          updateFormData({
            model: val,
            year: null,
            version: null,
          })
          clearError(['model', 'year', 'version'])
        }}
        value={formData.model}
        noOptionsText={NO_OPTIONS}
        options={models}
        disableClearable
        disabled={!formData.brand}
      />
      </div>
      <div id='select-year' className={classes.selectField}>
      <SelectSB
        label='Año'
        loading={isLoadingYears}
        error={errorList.includes('year') && formData.model}
        helperText={(formData.model && errorList.includes('year')) ? REQUIRED_ERROR_TEXT : null}
        onChange={ (val) => {
          updateFormData({
            year: val,
            version: null,
          })
          clearError(['year', 'version'])
        }}
        value={formData.year}
        noOptionsText={NO_OPTIONS}
        options={years}
        disableClearable
        disabled={!formData.model}
      />
      </div>
    </div>
    <div id='select-version'>
    <SelectSB
      label='Versión'
      loading={isLoadingVersions}
      error={errorList.includes('version') && formData.year}
      helperText={(formData.year && errorList.includes('version')) ? REQUIRED_ERROR_TEXT : null}
      onChange={ (val) => {
        updateFormData({ version: val })
        clearError(['version'])
      }}
      value={formData.version}
      noOptionsText={NO_OPTIONS}
      options={versions}
      disableClearable
      disabled={!formData.year}
    />
    </div>
    <TextFieldSB
     id='text-km'
      label='Kilometraje'
      value={formData.km}
      variant='outlined'
      type='number'
      error={errorList.includes('km')}
      isOptional
      onChange={ (val) => updateFormData({ km: val.target.value }) }
    />

    <div className={classes.button}>
      <Button id="boton-cotizar" onClick={handleSendButton} >
        <span id='boton-cotizar-txt' >Cotizar</span>
      </Button>
    </div>

  </div>
}
